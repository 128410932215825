<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" :pageNavs="pageNavs" />
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="sheet">
              <template v-slot:headline>イベント編集</template>
              <template v-slot:body>
                <transition name="fadeHeight" mode="out-in" @beforeLeave="beforeLeave" @enter="enter" @afterEnter="afterEnter">
                  <router-view ref="edit"/>
                </transition>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
    <!--[footer]-->
    <PageFooter>
      <template v-slot:footer>
        <ul class="listGrid">
          <li class="listGrid-item">
            <ul class="listGrid">
              <li class="listGrid-item">
                <button v-if="$permission.isGmo()" class="btn btn-red" type="button" @click="showModal('deleteModal')">削除</button>
              </li>
              <li class="listGrid-item">
                <button class="btn btn-yellow" type="button" @click="showModal('pauseModal')">{{ !isPaused ? '一時停止' : '再開'}}</button>
              </li>
              <li class="listGrid-item" v-if="isShowBtnRegistCustomer">
                <button
                  class="btn btn-green"
                  type="button"
                  :disabled="isImportDisabled"
                  @click="showModalRegistClients"
                >
                  顧客一括登録
                </button>
              </li>
            </ul>
          </li>
          <li class="listGrid-item pos-end">
            <ul class="listGrid">
              <li class="listGrid-item">
                <router-link class="btn btn-white" :to="{ name: 'SettingEventChild' }">キャンセル</router-link>
              </li>
              <li class="listGrid-item" v-if="routeName === baseRouteName">
                <button class="btn btn-bd-main" @click="changeRoute">詳細設定を編集する</button>
              </li>
              <li class="listGrid-item" v-if="routeName === detailRouteName">
                <button class="btn btn-bd-main" @click="changeRoute">基本設定を編集する</button>
              </li>
              <li class="listGrid-item">
                <ActionButton class="btn btn-blue" :handle-submit="edit" button-text="編集する" />
              </li>
            </ul>
          </li>
        </ul>
      </template>
    </PageFooter>
    <!--[/footer]-->
    <Modal @close="closeModal('deleteModal')" v-if="$permission.isGmo() && deleteModal">
      <template v-slot:headline>削除</template>
      <template v-slot:body>
        <p class="description text-align-center">対象のイベントを削除しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('deleteModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-red" :handle-submit="handleDeleteEvent" button-text="削除する" />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('pauseModal')" v-if="pauseModal">
      <template v-slot:headline>{{ isPaused ? '再開' : '一時停止' }}</template>
      <template v-if="!isPaused" v-slot:body>
        <p class="description text-align-center">一時停止し、このイベントの購入（チャージ）や支払いを止めますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-yellow" :handle-submit="changeStatusEvent" button-text="一時停止にする" />
          </li>
        </ul>
      </template>
      <template v-else v-slot:body>
        <p class="description text-align-center">一時停止を解除し、このイベントの購入（チャージ）や支払いを再開しますか？</p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('pauseModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-yellow" :handle-submit="changeStatusEvent" button-text="再開する" />
          </li>
        </ul>
      </template>
    </Modal>
    <Modal @close="closeModal('registClientsModal')" v-if="registClientsModal">
      <template v-slot:headline>顧客一括登録</template>
      <template v-slot:body>
        <p class="description text-align-center">
          {{ customerNoJoin | comma }}名のこの子イベントに参加していない顧客を一括で登録しますか？
          <br />
          ※一括登録は一度に登録できる最大は5,000名となります。
          <br />
          ※最大数より多くの顧客を登録する場合は、何度も実行する必要があります。
          <br />
          ※時間と負荷がかかる処理のため、子イベントを一時停止にした上で夜間や早朝に処理してください。
        </p>
        <ul class="listGrid justify-content-center">
          <li class="listGrid-item">
            <button class="btn btn-lg btn-white" type="button" @click="closeModal('registClientsModal')">キャンセル</button>
          </li>
          <li class="listGrid-item">
            <ActionButton class="btn btn-lg btn-green" :handle-submit="handleRegistClients" button-text="顧客を一括登録する" />
          </li>
        </ul>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import nav from '@/mixins/nav/setting';
import event from '@/mixins/module/event';
import fadeHeight from '@/mixins/plugin/fadeHeight';
import importStatus from '@/mixins/plugin/importStatus';
//modal
import Modal from '@/components/Modal.vue';
import modal from '@/mixins/plugin/modal';

export default {
  name: 'SettingEventEdit',
  data: function() {
    return {
      pageName: '設定',
      editPage: 'base',
      deleteModal: false,
      pauseModal: false,
      registClientsModal: false,
      baseRouteName: 'SettingEventChildEditBase',
      detailRouteName: 'SettingEventChildEditDetail',
      importingItem: 'REGIST_CUSTOMER',
    };
  },
  computed: {
    ...mapGetters({
      eventForm: 'event/childEventDetail',
      isClientChild: 'auth/isClientChild',
      customerNoJoin: 'event/customerNoJoin',
    }),
    isPaused() {
      return !this.eventForm.validFlag;
    },
    routeName() {
      return this.$route.name;
    },
    isShowBtnRegistCustomer() {
      return !!this.eventForm?.status?.id && !this.isClientChild;
    },
    importStatusOptions() {
      return {
        type: 'regist_customer',
        resetCheckbox: false,
        isDownloadCsv: false,
        isCountMessage: true,
        action: 'event/getRegistStatus',
        canUseAction: 'event/checkCanUseQueue',
        canUseParams: {
          id: this.eventForm?.id,
          eventId: this.eventForm?.id,
          type: ['register_all_customer']
        }
      }
    }
  },
  mixins: [nav, fadeHeight, modal, event, importStatus],
  components: {
    Modal,
  },
  methods: {
    edit: async function() {
      const isPassValidation = this.$refs.edit.validate();
      if (isPassValidation) {
        const payload = this.convertForm(this.eventForm);
        const result = await this.$store.dispatch('event/updateChildEvent', payload);
        if (result) {
          await this.$router.push({ name: 'SettingEventChild' });
          this.$message.updated('childEvent');
        }
      }
    },
    async handleDeleteEvent() {
      const result = await this.$store.dispatch('event/deleteChildEvent');
      this.closeModal('deleteModal');
      if (result) {
        await this.$router.push({ name: 'SettingEventChild' });
        this.$message.deleted('childEvent');
      }
    },
    async handleRegistClients() {
      const result = await this.$store.dispatch('event/registClients');
      this.closeModal('registClientsModal');
      if (result) {
        this.setImportingCsv(result?.data?.id);
        this.$message.showMessageSuccess(result?.data?.message);
        this.checkImportStatus(result?.data?.id);
      }
    },
    async showModalRegistClients() {
      await this.$store.dispatch('event/getChildEventDetail'),
      this.showModal('registClientsModal');
    },
    async changeStatusEvent() {
      const result = await this.$store.dispatch('event/changeStatusChildEvent', {
        validFlag: this.isPaused,
      })
      this.closeModal('pauseModal');
      if (result) {
        this.$message.updated('eventStatus');
        await this.$store.dispatch('event/getChildEventDetail');
      }
    },
    changeRoute: function() {
      const isPassValidation = this.$refs.edit.validate();
      if (isPassValidation) {
        if (this.routeName === this.baseRouteName) {
          this.$router.push({ name: this.detailRouteName })
        } else {
          this.$router.push({ name: this.baseRouteName })
        }
      }
    },
  },
};
</script>
